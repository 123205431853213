<template>
  <i :class="iconClass" />
</template>

<script>
export default {
  props: {
    icon: {type: String, required: false},
  },
  computed: {
    iconClass(){
      return `fa fas fa-${this.icon}`
    }
  }
}
</script>